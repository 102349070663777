<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('assign.create_assignment')"
    @close="winShow = false"
    width="900px">
    <div class="form">
    <custem-form
      ref="form"
      :loading="formLoading"
      :formData="formData"
      :formRules="formRules"
      @change="change"
      @tipClick="tipClick"
      @refresh="refresh">
      <template #order_id>
        <order-listAssi ref="order_list" @pushOrder="pushOrder"></order-listAssi>
        <custem-detail
          v-if="!detailLoading"
          :loading="detailLoading"
          :descriptions="descriptions" />
      </template>
      <!-- 新增接收邮箱 -->
      <template #add_mail>
        <div class="add_mail">
          <div v-for="item in formData.add_mail.value" class="mail-item">
            <el-input @blur="checkMailNull(item)" v-model="item.value" :placeholder="t('form.inputPlaceholder')" :class="{ 'is-error': item.error }" />
            <span @click="delMail(item.id)">{{ t('form.delete') }}</span>
            <div v-if="item.error" class="el-form-item__error">{{ item.error }}</div>
          </div>
          <el-button v-if="formData.add_mail.value.length < 3" type="primary" plain @click="addMail">+ {{ t('assign.add_mail') }}</el-button>
        </div>
      </template>
      <template #timeType>
        <el-radio-group v-model="formVal.timeType">
          <div>
            <el-radio value="1">{{ t('assign.effective_immediately') }}</el-radio>
            <div class="start_at">
              <el-radio value="2">{{ t('assign.scheduled') }}</el-radio>
              <div v-show="formVal.timeType === '2'">
                <el-date-picker
                  v-model="formVal.start_at"
                  :placeholder="t('assign.select_effective_time')"
                  :clearable="false"
                  :disabled-date="disabledDate"
                  type="datetime" />
              </div>
            </div>
          </div>
        </el-radio-group>
      </template>
    </custem-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="onSubmit" :loading="submitLoading" type="primary">{{ t('form.submit') }}</el-button>
        <el-button @click="winShow = false">{{ t('form.cancel') }}</el-button>
      </div>
    </template>
    <!-- 新增项目表单 -->
    <create-project-form ref="createProjectRef" />
  </el-dialog>
</template>
<script setup>
import { ref, inject, reactive, nextTick, watch, getCurrentInstance } from 'vue'
import { rules } from '@/utils/rules'
import { AuthManagement, userManagement } from '@/utils/api'
import CreateProjectForm from '../../Project/components/Form.vue'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

const t = inject('t')
const { proxy } = getCurrentInstance()
const formLoading = ref(true)
const detailLoading = ref(true)
const winShow = ref(false)
const submitLoading = ref(false)
const createProjectRef = ref()
const order_list = ref()
const form = ref()
const product_id = ref(null) // 项目绑定的产品id
const mail_id = ref(1) // 新增接收邮箱的id
const endTime = ref(null)
const emit = defineEmits(['getList'])
const formVal = reactive({
  params: {},
  start_at: new Date(),
  timeType: '1' // 默认即时生效
}) // 提交的表单
const formRules = reactive({
  assignmenTypes: rules,
  lic_key: rules,
  project_id: rules,
  order_id: rules,
  authorization_type: rules,
  lic_key1: rules,
  user_product: rules,
  timeType: rules,
  send_flag: rules
})
const formData = reactive({
  assignmenTypes: { // 分配方式 - 默认按项目
    type: 'radio',
    label: t('assign.type'),
    value: 1,
    options: [
      {
        name: t('assign.allocate_by_project'),
        id: 1
      },
      {
        name: t('assign.allocate_by_license'),
        id: 2
      }
    ]
  },
  lic_key: { // 注册码
    type: 'textarea',
    label: t('assign.license_key'),
    hidden: true
  },
  project_id: { // 选择项目
    label: t('assign.select_project'),
    type: 'select',
    options: [],
    tips: `<div class="noproject">${t('assign.no_project')}，
            <span class="createProject" @click="createProject">${t('assign.click_to_create')}</span>
          </div>`,
    showRefresh: true
  },
  order_id: { // 选择订单
    label: t('assign.select_order'),
    type: 'slot'
  },
  authorization_type: { // 授权方式
    label: t('assign.authorization_method'),
    type: 'radio',
    value: 1,
    hidden: true,
    options: [
      {
        name: t('assign.author_license'),
        id: 1
      },
      {
        name: t('assign.author_user'),
        id: 2
      }
    ]
  },
  lic_key1: { // 注册码授权
    type: 'textarea',
    label: t('assign.license_key')
  },
  user_product: { // 产品用户授权
    label: t('assign.select_pro_user'),
    type: 'select',
    options: [],
    hidden: true,
    showRefresh: true
  },
  add_mail: { // 新增接收邮箱
    label: t('assign.add_mail'),
    type: 'slot',
    value: []
  },
  timeType: { // 授权生效时间
    label: t('assign.license_start_at'),
    type: 'slot',
    value: '1',
    hidden: true
  },
  send_flag: { // 发送邮件类型
    label: t('assign.send_mail_type'),
    type: 'radio',
    options: [],
    hidden: true,
    value: 'standard'
  },
  comment: { // 备注
    label: t('user.notes'),
    type: 'textarea',
    value: ''
  }
})
// 产品详情
const descriptions = reactive([
  { // 产品简称
    label: t('product.product_abbreviation'),
    prop: 'name'
  },
  { // 产品编码
    label: t('product.product_code'),
    prop: 'code'
  },
  { // 授权接收邮箱
    label: t('assign.recipient_mail'),
    prop: 'project_mail'
  },
  { // 子用户邮箱
    label: t('user.sub_user_mail'),
    prop: 'liaison_mail'
  }
])
// 禁用可选时间
const disabledDate = (date) => {
  return new Date(date).getTime() > endTime.value || new Date(date).getTime() < new Date().getTime()
}
// 新增接收邮箱
const addMail = () => {
  formData.add_mail.value.push({
    id: mail_id.value++,
    value: '',
    error: null
  })
}
// 删除邮箱
const delMail = (id) => {
  const index = formData.add_mail.value.findIndex(ele => ele.id === id)
  formData.add_mail.value.splice(index, 1)
}
// 检验是否没有输入邮箱
const checkMailNull = (item) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  item.error = !item.value ? t('form.emptyErrMsg') : !emailPattern.test(item.value) ? t('form.error_mail') : false
}
// 已选订单
const pushOrder = (data) => {
  formData.order_id.value = data.map(ele => {
    return {
      order_id: ele.id,
      content: {
        hosts: ele.number
      },
      expired_at: new Date(dayjs(ele.expired_at).format('YYYY-MM-DD HH:mm:ss')).getTime()
    }
  })
}
// 表单change事件
const change = async ({ item, prop, event }) => {
  if (prop === 'assignmenTypes') { // 分配方式
    formLoading.value = true
    formData.lic_key.hidden = item.value === 1
    formData.lic_key.value = null
    formData.lic_key1.hidden = item.value === 2
    formData.lic_key1.disabled = item.value === 2
    formData.lic_key1.value = null
    formData.send_flag.hidden = true
    detailLoading.value = true
    formData.order_id.value = null
    formData[prop].value = item.value
    formData.project_id.value = null
    formData.project_id.disabled = item.value === 2
    formData.project_id.showRefresh = item.value === 1
    formData.project_id.tips = item.value === 2 ? '' : `<div class="noproject">${t('assign.no_project')}，<span class="createProject" @click="createProject">${t('assign.click_to_create')}</span></div>`
    await nextTick()
    order_list.value.clearOrderList()
  } else if (prop === 'lic_key') { // 根据注册码查询项目信息
    formData.project_id.value = null
    if (item.value) getProjectList()
  } else if (prop === 'project_id') {
    getProjectInfo() // 获取项目详情信息
  } else if (prop === 'authorization_type') { // 授权方式改变 
    formData.user_product.hidden = item.value !== 2 // 产品用户授权
    formData.user_product.value = item.value === 2 ? null : formData.user_product.value
    formData.lic_key1.value = item.value === 1 ? null : formData.lic_key1.value
    formData.lic_key1.hidden = item.value !== 1 // 产品用户授权
  } else if (prop === 'user_product') { // 产品用户授权改变
    const obj = item.options.find(ele => ele.id === item.value)
    if (obj) {
      formVal.params = {
        ...formVal.params,
        saas_user_id: obj.id,
        saas_ent_id: obj.enterprise_id,
        saas_user: obj.name
      }
    }
  }
}
// 获取项目列表
const getProjectList = async () => {
  const searchData = formData.assignmenTypes.value === 2 ? { filter_feature_code: encodeURIComponent(formData.lic_key.value) } : {}
  await nextTick()
  if (!formData.project_id.hidden) formData.project_id.loading = true
  try {
    const res = await AuthManagement.ProjectList({
      ...searchData,
      is_paginate: false,
      filter_effectuate: true
    })
    if (!formData.project_id.hidden) formData.project_id.loading = false
    formData.project_id.options = res.items
    // 处理空数据
    if (!res.items.length && !formLoading.value) {
      if (order_list.value) order_list.value.clearOrderList()
      detailLoading.value = true
      formData.order_id.value = null
      return
    }
    if (formData.assignmenTypes.value === 2) { // 按项目分配时选择第一个项目
      formData.project_id.value = res.items[0].id
      getProjectInfo()
      return
    }
    formLoading.value = false
  } catch (error) {
    formLoading.value = false
    if (!formData.project_id.hidden) formData.project_id.loading = false
  }
}
// 获取项目详情
const getProjectInfo = async (order_id = null, number = 1) => {
  const res = await AuthManagement.ProjectDetail(formData.project_id.value)
  formLoading.value = false
  formVal.failback_key = res.failback_key || '' // 回切注册码
  formData.lic_key1.value = res.lic_key || '' // 注册码
  formData.lic_key1.disabled = !!res.lic_key // 有注册码的时候禁止编辑
  formData.authorization_type.hidden = !formVal.is_automatic // 自动下发显示授权方式
  formVal.saas_id = res.saas_id || '' // saas用户id
  detailLoading.value = true
  if (res.product) { // 产品相关数据渲染
    formVal.is_automatic = res.product.params && res.product.params.is_automatic || false // 是否自动下发
    descriptions.forEach(item => {
      if (item.prop === 'project_mail') {
        item.value = res.mail || '-'
      } else if (item.prop === 'liaison_mail') {
        item.value = res?.sub_user?.params?.liaison_mail
      } else {
        item.value = res.product[item.prop] || '-'
      }
    })
    product_id.value = res.product.id || null
    detailLoading.value = false
    if (res.product.category.id === 1001) {
      // 迁移发送邮件列表
      formData.send_flag.options = [
        {
          value: 'standard',
          label: t('assign.send_migraiton_mail')
        }
      ]
    } else {
      // 发送容灾邮件
      formData.send_flag.options = [
        {
          value: 'standard',
          label: t('assign.send_dr_failback_mail')
        },
        {
          value: 'dr',
          label: t('assign.send_dr_mail')
        },
        {
          value: 'failback',
          label: t('assign.send_failbackr_mail')
        }
      ]
    }
    formData.send_flag.hidden = !formData.send_flag.options.length
    formData.send_flag.value = !formData.send_flag.options.length && 'standard' || 'standard'
    // 获取订单列表
    await nextTick()
    order_list.value.getOrderList({
      filter_assignment_project_id: formData.project_id.value,
      filter_product_id: res.product.id,
    }, order_id, number)
  } else {
    ElMessage.error(t('assign.no_project_product'))
    order_list.value.clearOrderList()
    formData.send_flag.hidden = true
    return
  }
}
// 获取产品用户列表
const getProductUserList = async () => {
  await nextTick()
  let search = {}
  if (formVal.saas_id) search = { saas_id: formVal.saas_id }
  formData.user_product.loading = true
  formData.user_product.value = null
  try {
    const res = await userManagement.productUser(product_id.value, search)
    res.pages.forEach(item => {
      item.name = `${item.username}_${item.mobile}`
    })
    formData.user_product.options = res.pages
    formData.user_product.loading = false
  } catch (error) {
    formData.user_product.loading = false
  }
}
// 提示文字点击事件
const tipClick = async (event) => {
  await nextTick()
  if (event.target.classList.contains('createProject')) { // 创建项目
    createProjectRef.value.openWin()
  }
}
// 刷新事件
const refresh = ({ item, prop, event }) => {
  if (prop === 'project_id') { // 刷新项目列表
    getProjectList()
  } else if (prop === 'user_product') { // 刷新产品用户列表
    getProductUserList()
  }
}
// 表单提交
const onSubmit = async () => {
  // 提交表单并检查结果
  const res = await form.value.submitForm()
  if (!res) return
  // 检查邮箱字段的错误
  formData.add_mail.value.forEach(item => {
    item.error = !item.value
  })
  // 检查订单 ID 和邮箱字段的有效性
  if (!formData.order_id.value || !Object.keys(formData.order_id.value).length || 
    formData.add_mail.value.some(item => !item.value)) {
    return
  }
  submitLoading.value = true
  // 准备表单数据
  const formValData = { ...formVal } // 使用展开运算符创建副本
  formValData.start_at = formValData.timeType === '1' ? proxy.$startTime() : proxy.$startTime(formValData.start_at)
  const postArr = []
  for (const key in formData) {
    const value = formData[key].value
    if (value !== undefined && value !== '') {
      switch (key) {
        case 'lic_key1':
          formValData['lic_key'] = value
          break;
        case 'add_mail':
          formValData.params.custom_mail = formData.add_mail.value.map(item => item.value).join(',')
          break;
        case 'authorization_type':
          formValData.params[key] = value
          break;
        default:
          formValData[key] = value
      }
    }
  }
  // 删除订单 ID 字段
  delete formValData.order_id
  // 准备要发送的请求
  formData.order_id.value.forEach(item => {
    const postItem = {
      product_id: product_id.value,
      ...item,
      ...formValData
    }
    postArr.push(AuthManagement.AssignmentCreate(postItem))
  })

  // 等待下一个 DOM 更新周期
  await nextTick()
  // 执行所有请求并处理结果
  try {
    await Promise.all(postArr)
    submitLoading.value = false
    winShow.value = false
    ElMessage.success(t('table.add_success'))
    emit('getList')
  } catch (error) {
    // 处理错误（可以根据需要进一步处理）
    console.error('Error during submission:', error)
    submitLoading.value = false
  }
}

// 清除表单数据
const clearForm = () => {
  form.value.clearForm()
  order_list.value.clearOrderList()
  detailLoading.value = true
  formData.send_flag.hidden = true
  formData.send_flag.options = []
  formData.order_id.value = null
  formData.add_mail.value = []
  formVal.params = {}
  formVal.timeType = '1' // 默认即时生效
  formLoading.value = false
  formData.assignmenTypes.disabled = false
  formData.project_id.disabled = false
  formData.project_id.value = null
  formData.lic_key.hidden = formData.assignmenTypes.value === 1
}
// 打开弹框
const openWin = async (data = null) => {
  winShow.value = true
  await nextTick()
  clearForm()
  if (!!data) {
    console.log('data', data)
    // 再次下发
    formLoading.value = true
    formData.assignmenTypes.disabled = true
    formData.project_id.value = data.project.id
    formData.project_id.disabled = true
    if (data.project.id) getProjectInfo()
  }
}
watch(() => formData.assignmenTypes.value, val => {
  if (val) getProjectList()
}, { deep: true, immediate: true })
// 监听授权方式获取产品用户列表
watch(() => formData.authorization_type.value, val => {
  if (!val || val === 1) return
  getProductUserList()
}, { deep: true, immediate: true })
// 监听是否选中订单
// 监听订单，设置定时生效时间的开始时间
watch(() => formData.order_id.value, async val => {
  await nextTick()
  formData.timeType.hidden = !(val && val.length)
  if (!val || !val.length) return
  endTime.value = Math.min.apply(Math, val.map(item => {
    return item.expired_at
  }))
}, { immediate: true, deep: true })
defineExpose({
  openWin
})
</script>

<style lang="scss" scoped>
:deep(.noproject) {
  margin-top: 5px;
  font-size: 12px;

  span {
    cursor: pointer;
    color: var(--el-color-primary);
  }
}
.mail-item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &>span {
    width: 100px;
    margin-left: 8px;
    cursor: pointer;
    font-size: 14px;
  }
  .is-error {
    :deep(.el-input__wrapper) {
      box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
    }
  }
}
.start_at {
  display: flex;
  align-items: center;
}
</style>