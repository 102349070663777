<template>
  <custem-table
    :tableLoading="tableLoading"
    :tableInfo="tableInfo"
    @getList="getList" />
  <!-- 详情 -->
  <Detail ref="detail" />
</template>
<script setup>
import { getCurrentInstance, reactive, inject, ref, watch } from 'vue'
import { AuthManagement } from '@/utils/api'
import Detail from './Detail'
import { useRoute } from 'vue-router'
const route = useRoute()
const { proxy } = getCurrentInstance()
const t = inject('t')
const tableLoading = ref(false)
const detail = ref()
const emit = defineEmits(['reissue'])

// 详情
const detailData = (data) => {
  if (!!!detail.value) return
  detail.value.openWin(data, true)
}
// 再次下发
const reissue = (data) => {
  emit('reissue', data)
}
const tableInfo = reactive({
  header: [
    { // 创建时间
      key: 'created_at',
      label: t('table.creation_time'),
      minWidth: '180px'
    },
    { // 授权编码
      key: 'id',
      label: t('assign.license_code'),
      minWidth: '180px'
    },
    { // 所属项目
      key: 'project',
      label: t('assign.project'),
      custem: (data) => {
        return data.project ? data.project.name : '-'
      },
      minWidth: '180px'
    },
    { // 产品名称
      key: 'product',
      label: t('product.product_name'),
      custem: (data) => {
        return data.project ? (data.project.product ? data.project.product.name : '-') : '-'
      },
      minWidth: '180px'
    },
    { // 订单编号
      key: 'created_at',
      label: t('order.order_code'),
      custem: (data) => {
        return data.order ? data.order.code : '-'
      },
      minWidth: '180px'
    },
    { // 订单类别
      key: 'created_at',
      label: t('order.order_type'),
      custem: (data) => {
        return data.order ? proxy.$formatType(data.order.category_id, data.order.params.service_type === 'failback' ? t('order.failback') : '') : '-'
      },
      minWidth: '180px'
    },
    { // 分配数量
      key: 'created_at',
      label: t('order.allocation_count'),
      custem: (data) => {
        return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '-'
      },
      minWidth: '180px'
    },
    { // 使用周期
      key: 'created_at',
      label: t('order.order_cycle'),
      custem: (data) => {
        return data.order ? `${proxy.$numberToCurrencyNo(data.order.use_period)} ${Number(data.order.use_period) > 1 ? t('system.days') : t('system.day')}` : '-'
      },
      minWidth: '180px'
    },
    { // 使用到期时间
      key: 'expired_at',
      label: t('order.expiration_time'),
      minWidth: '180px'
    },
    {
      label: t('table.action'),
      minWidth: '80px',
      fixed: 'right',
      prop: 'action',
      handleFun: [
        {
          name: t('table.detail'),
          fn: detailData,
        }
      ]
    }
  ]
})
// 获取列表
const getList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.AssignmentList({
      filter_category_id: 0,
      ...search
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
defineExpose({
  getList
})
watch(() => route.fullPath, val => {
  if (!val) return
  tableInfo.header.forEach(item => {
    if (item.prop === 'action') {
      if (item.handleFun.length === 1) {
        item.width = 140
        item.handleFun.push({
          name: t('assign.reissue'),
          fn: reissue
        })
      }
    }
  })
}, { deep: true, immediate: true })
</script>